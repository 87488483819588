import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage, infoMessage } from "../../../utils";
import {
  editDoordash,
  getDoordashList,
  onActOrDeact,
  onboardSetUp,
  OnResetOnboardApi,
  uploadAllMenuDoordash,
  uploadPushMenuDoordash,
} from "../api/doordashApi";
import { usePermission, useTabs } from "../../../shared";
import { AppContext, AuthContext } from "../../../store";
import { useMenuTemplate } from "../../MenuTemplate";
import { createTemplate, showChannel } from "../../Menulog/api/menuLogApi";
import { getStoreDetailsById } from "../../Stores";

export const useDoordash = ({ isList }) => {
  const isEditable = usePermission("doordash-modify");
  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const { doordash: currentTab } = appState.currentTabs;
  const { setCurentTab } = useTabs();
  const { state: stateMenuTempalte } = useMenuTemplate({ isList: true });
  const optionsMenuTemplate = stateMenuTempalte?.menuTemplates?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    isActOrDeactBusy: null,
    isDialogOpen: false,
    isAllSaveButton: false,
    isSaveButton: false,
    isSaveButtonBusy: false,
    isResetButtonBusy: false,
    isDoordashErrorOpen: false,
    doordashData: [],
    store_id: null,
    id: "",
    data: {
      store_id: 2,
      doordash: 1,
    },
    isErrorOpen: false,
    errorMessages: null,
    dataMenu: {
      channel_id: 104,
      menu_id: "",
    },
    storeDetails: {
      requestor_email: "",
      merchant_decision_maker_email: "",
      // doordash_store_id: "",
      partner_store_id: "",
      partner_store_name: "",
      provider_type: "",
      requestor_first_name: "",
      requestor_last_name: "",
      requestor_email: "",
      address_city: "",
      address_state: "",
      address_zip: "",
      address_line_1: "",
    },
  });
  useEffect(() => {
    if (isList) {
      getDoordashStores();
    }
  }, [isList]);
  const getDoordashStores = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getDoordashList();
      if (res.success) {
        setState((draft) => {
          draft.doordashData = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleAllMenu = async () => {
    try {
      setState((draft) => {
        draft.isAllSaveButton = true;
      });
      const res = await uploadAllMenuDoordash();
      if (res.status === 200) {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        infoMessage(res.data.message);
      } else {
        setState((draft) => {
          draft.isAllSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isAllSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };

  const handlePushMenu = async (id, data) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
        draft.store_id = id;
      });
      const res = await uploadPushMenuDoordash(id);
      infoMessage(res.data.message);
      getDoordashStores();
      if (res.status === 200) {
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        setState((draft) => {
          draft.isSaveButton = false;
        });
        errorMessage("Error Occured!");
      }
    } catch (err) {
      setState((draft) => {
        draft.isSaveButton = false;
      });
      errorMessage("Error Occured!");
    }
  };
  const toggleAction = (type, e, value) => {
    setState((draft) => {
      draft.isOpen = type;
      draft.data.store_id = value?.store_id;
      draft.data.doordash = e ? 1 : 0;
    });
  };

  const handleUpdate = async (value, e, type) => {
    let payload = {
      store_id: value?.store_id,
      // ubereats_store_id: value?.ubereats_store_id,
      doordash: e ? 1 : 0,
    };
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editDoordash(type === "active" ? payload : state.data);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        getDoordashStores();
        toggleAction(false);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const isErrorModal = (v, text) => {
    setState((draft) => {
      draft.isErrorOpen = v;
      draft.errorMessages = text;
    });
  };
  const isDoordashErrorModal = (v, text) => {
    setState((draft) => {
      draft.isDoordashErrorOpen = v;
      draft.errorMessages = text;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.dataMenu[name] = value;
    });
  };
  const handleSubmit = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createTemplate(state.dataMenu);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getTemplatesDetails = async (channel_id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await showChannel(channel_id);
      if (res.data) {
        setState((draft) => {
          draft.dataMenu.menu_id = res?.data?.id;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (currentTab === "2") {
      getTemplatesDetails(state?.dataMenu?.channel_id);
    }
  }, [currentTab]);
  const handleSetup = async (type, id) => {
    setState((draft) => {
      draft.id = id;
      draft.isDialogOpen = type;
    });
  };
  const getDoordashStoreDetail = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getStoreDetailsById(id);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.storeDetails = {
            ...res.data,
            partner_store_name: res?.data?.name,
            partner_store_id: res?.data?.id,
            requestor_first_name: auth.data.first_name,
            requestor_last_name: auth.data.last_name,
            address_city: res.data.city.name,
            address_state: res.data.state.name,
            address_zip: res.data.zip_code,
            address_line_1: res.data.address,
          };
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (state.isDialogOpen) {
      getDoordashStoreDetail(state?.id);
    }
  }, [state.isDialogOpen]);

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.storeDetails[name] = value;
    });
  };
  const handleSetupDoordash = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const {
        requestor_email,
        merchant_decision_maker_email,
        doordash_store_id,
        partner_store_name,
        requestor_first_name,
        requestor_last_name,
        address_city,
        address_state,
        address_zip,
        address_line_1 } = state.storeDetails

      const payload = {
        requestor_email,
        merchant_decision_maker_email,
        doordash_store_id,
        partner_store_name,
        requestor_first_name,
        requestor_last_name,
        address_city,
        address_state,
        address_zip,
        address_line_1,
      };
      const res = await onboardSetUp(state.id, payload);
      if (res.success) {
        successMessage("Doordash Onboard successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        handleSetup(false);
        getDoordashStores();
      } else {
        errorMessage(res.message)
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onActivaOrDeactivate = async (id) => {
    try {
      const res = await onActOrDeact(id);
      if (res.success) {
        successMessage("updated successfully");
        setState((draft) => {
          draft.isActOrDeactBusy = null;
        });
        getDoordashStores();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isActOrDeactBusy = null;
      });
    }
  };

  const OnResetOnboard = async (id) => {
    try {
      const res = await OnResetOnboardApi(id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isResetButtonBusy = null;
        });
        getDoordashStores();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isResetButtonBusy = null;
      });
    }
  }

  return {
    state,
    handleAllMenu,
    toggleAction,
    handlePushMenu,
    onChange,
    handleUpdate,
    isEditable,
    isErrorModal,
    currentTab,
    setCurentTab,
    optionsMenuTemplate,
    handleInputChange,
    handleSubmit,
    handleSetup,
    handleSetupDoordash,
    isDoordashErrorModal,
    onActivaOrDeactivate,
    setState,
    OnResetOnboard,
  };
};
