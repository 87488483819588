import { useState } from "react";
import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  Divider,
  Radio,
  TextField,
  FormHelperText,
  Tooltip,
  InputAdornment,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import InputControl from "../../../shared/components/InputControl";
import { DayPicker } from "../components/DayPicker";
import { DropzoneComponents } from "../../BirthdayCoupons/components";
import { useCoupons } from "../hooks/useCoupons";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useImmer } from "use-immer";

const ToggleButtonStyled = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#1560D4",
  },
});

export const AddOrEditCoupons = () => {
  const { id } = useParams();
  const {
    state,
    onChange,
    onStartDateChange,
    onAddConditions,
    handleOnChange,
    onSaveCoupons,
    onUpdateCoupons,
    handleInputChange,
    onDayPicker,
    handleDelete,
    handleConditionType,
    handleSwitchChange,
    handleCheckBoxChange,
    onRadioButtonChange,
    onBlurTab,
  } = useCoupons({
    isCoupons: false,
    couponsById: id,
    isStore: false,
    isConditions: true,
  });
  const [validate, setValidate] = useImmer({
    validation: {
      isValidated: false,
      start_from: null,
      end_till: null,
    },
  });
  const { guest } = state.checked

  const [, forceUpdate] = useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endDate: {
          message: "The end date should greater than start date",
          rule: (value) => {
            const { startDate, endDate } = value;
            return moment(startDate, "YYYY-MM-DD").isSameOrBefore(
              moment(endDate, "YYYY-MM-DD")
            );
          },
        },
        startDate: {
          message: "Please enter a date that is today or later.",
          rule: (value) => {
            return moment(value ?? "").isSameOrAfter(
              moment().format("YYYY-MM-DD")
            );
          },
        },
        customMaxQty: {
          message: "The Maximum Product Qty must be 0, 1, or empty.",
          rule: (val) => val == "" || val === null || val == 0 || val == 1,
        },
      },
    })
  );

  let options = state.conditions
    .filter((e) => (state.checked.guest ? 2 !== e.scope : e))
    .map((e) => ({ value: e.id, label: e.name }));
  const isAllValid = () => {
    const { start_from, end_till } = validate.validation;
    if (start_from === null && end_till === null) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmit = () => {
    if (validator.current.allValid() && isAllValid()) {
      id ? onUpdateCoupons(id) : onSaveCoupons();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setValidate((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };
  const validateDate = (reason, name, type) => {
    if (reason === "disablePast") {
      setValidate((draft) => {
        draft.validation[name] = "Please select a future date";
      });
    } else if (reason === "invalidDate") {
      setValidate((draft) => {
        draft.validation[name] =
          type === "date"
            ? "Selected Date is not a valid date"
            : "Selected Time is not valid";
      });
    } else {
      setValidate((draft) => {
        draft.validation[name] = null;
      });
    }
  };
  const Days = [
    {
      value: "Monday",
      label: "MON",
    },
    {
      value: "Tuesday",
      label: "TUE",
    },
    {
      value: "Wednesday",
      label: "WED",
    },
    {
      value: "Thursday",
      label: "THU",
    },
    {
      value: "Friday",
      label: "FRI",
    },
    {
      value: "Saturday",
      label: "SAT",
    },
    {
      value: "Sunday",
      label: "SUN",
    },
  ];
  const conditionType = [
    { label: "AND", value: 1 },
    { label: "OR", value: 2 },
  ];
  const disabledValue = state.data.conditions.map((i) => i.id).includes("");
  return (
    <>
      <ContentLayout
        title={id ? state.name : "New Coupon"}
        breadcrumbs={
          id ? (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Coupons", href: "/coupons" },
                  { name: state.name, href: `/coupons/details/${id}` },
                  { name: "Edit" },
                ]}
              />
            </>
          ) : (
            <>
              <HeaderBreadcrumbs
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Coupons", href: "/coupons" },
                  { name: "Create" },
                ]}
              />
            </>
          )
        }
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {id ? "Update" : "Save"}
            </Button>
          </>
        }
      >
        <Card sx={{ p: "32px 24px", mb: "24px" }}>
          {validator.current.purgeFields()}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Details
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="20px">
                <InputControl
                  name="name"
                  label="Coupon Name"
                  onChange={(e) => onChange(e)}
                  value={state.data.name}
                  error={validator.current.message(
                    "Coupon Name",
                    state.data.name,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Coupon Name",
                    state.data.name,
                    "required"
                  )}
                  required
                />
                <InputControl
                  type="outlinedInput"
                  name="label"
                  onChange={(e) => onChange(e)}
                  value={state.data.label}
                  inputLabel="Label"
                  label="Label"
                  placeholder="Label"
                  required
                  error={validator.current.message(
                    "Label",
                    state.data.label,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Label",
                    state.data.label,
                    "required"
                  )}
                  endAdornment={
                    <InputAdornment position="start">
                      <Tooltip title="Identifier used in admin panel to identify the coupon. Cannot have duplicate labels.">
                        <img alt="details" src="/icons/ic_info.svg" />
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <Divider />
          </Box>
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Description
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack gap="2px">
                <ReactQuill
                  placeholder="Description Text *"
                  theme="snow"
                  value={state.data.description}
                  onChange={(e) => {
                    onChange({
                      target: {
                        name: "description",
                        value: e,
                      },
                    });
                  }}
                />
                <FormHelperText sx={{ color: "#C60808" }}>
                  {validator.current.message(
                    "Description",
                    state.data.description.replace(/<(.|\n)*?>/g, "").trim(),
                    "required"
                  )}
                </FormHelperText>
              </Stack>
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <Divider />
          </Box>
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Images
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack direction={"row"} spacing={2}>
                <Stack>
                  <DropzoneComponents
                    imgUrl={state.data.mobile_image}
                    handleDropzone={(e) => {
                      handleInputChange({
                        target: {
                          name: "mobile_image",
                          value: e,
                        },
                      });
                    }}
                    aspectRatio="1/1"
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Image",
                      state.data.mobile_image,
                      "IconSize|IconFormat"
                    )}
                  </FormHelperText>
                </Stack>
                <Stack>
                  <DropzoneComponents
                    imgUrl={state.data.desktop_image}
                    handleDropzone={(e) => {
                      handleInputChange({
                        target: {
                          name: "desktop_image",
                          value: e,
                        },
                      });
                    }}
                    aspectRatio="2/1"
                  />
                  <FormHelperText sx={{ color: "#C60808" }}>
                    {validator.current.message(
                      "Image",
                      state.data.desktop_image,
                      "IconSize|IconFormat"
                    )}
                  </FormHelperText>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Divider />
          </Box>

          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "196px" }}
              >
                Show promotion in POS
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack direction="row" gap="20px">
                <Switch
                  name="show_in_list"
                  checked={state.data.show_in_list === 1 ? true : false}
                  onChange={(e) => {
                    handleSwitchChange(e);
                  }}
                />
              </Stack>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Divider />
          </Box>
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Date and Time
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack direction="row" gap="20px">
                <InputControl
                  type="datePicker"
                  disablePast={true}
                  name="start_from"
                  label="Start Date"
                  inputFormat="dd/MM/yyyy"
                  onError={(reason, value) =>
                    validateDate(reason, "start_from", "date")
                  }
                  sx={{
                    width: "100%",
                  }}
                  required
                  value={
                    state.data.start_from ? state.data.start_from : null
                  }
                  onChange={(e) => {
                    onStartDateChange(e, "start_from");
                  }}
                  error={validator.current.message(
                    "start date",
                    state.data.start_from,
                    "required|startDate"
                  )}
                  helperText={validator.current.message(
                    "start date",
                    state.data.start_from,
                    "required|startDate"
                  )}
                />

                <InputControl
                  type="datePicker"
                  disablePast={true}
                  onError={(reason, value) =>
                    validateDate(reason, "end_till", "date")
                  }
                  name="end_till"
                  label="End Date"
                  inputFormat="dd/MM/yyyy"
                  sx={{
                    width: "100%",
                  }}
                  required
                  value={state.data.end_till ? state.data.end_till : null}
                  onChange={(e) => {
                    onStartDateChange(e, "end_till");
                  }}
                  error={
                    !state.data.start_from
                      ? validator.current.message(
                        "end date",
                        state.data.end_till,
                        "required"
                      )
                      : validator.current.message(
                        "end date",
                        {
                          startDate: state.data.start_from,
                          endDate: state.data.end_till,
                        },
                        "required|endDate"
                      )
                  }
                  helperText={
                    !state.data.start_from
                      ? validator.current.message(
                        "end date",
                        state.data.end_till,
                        "required"
                      )
                      : validator.current.message(
                        "end date",
                        {
                          startDate: state.data.start_from,
                          endDate: state.data.end_till,
                        },
                        "required|endDate"
                      )
                  }
                />
              </Stack>
              <Grid
                container
                my={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item sm={2} md={2}>
                  <Typography color={"#636363"}>Day(s)</Typography>
                </Grid>
                <Grid item sm={10} md={10}>
                  <DayPicker
                    options={Days}
                    onDayPicker={onDayPicker}
                    data={state.data?.days_of_week}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" gap="20px" mt={2}>
                <InputControl
                  ampm={false}
                  type="timePicker"
                  label="Start Time"
                  name="start_time"
                  value={
                    state.data.start_time
                      ? moment(state.data.start_time, "HH:mm:ss").format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                      : null
                  }
                  onChange={(e) => {
                    onChange({
                      target: {
                        name: "start_time",
                        value: e,
                      },
                    });
                  }}
                />

                <InputControl
                  ampm={false}
                  type="timePicker"
                  label="End Time"
                  name="end_time"
                  value={
                    state.data.end_time
                      ? moment(state.data.end_time, "HH:mm:ss").format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                      : null
                  }
                  onChange={(e) => {
                    onChange({
                      target: {
                        name: "end_time",
                        value: e,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          {state.data.active === 1 && (
            <>
              <Box marginTop={3}>
                <Divider />
              </Box>

              <Grid container spacing={2} marginTop={3}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Usage Restrictions
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checked.inStore}
                          onChange={(e) => handleCheckBoxChange(e, "inStore")}
                        />
                      }
                      label="In-Store"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.checked.onlineOrdering}
                          onChange={(e) =>
                            handleCheckBoxChange(e, "onlineOrdering")
                          }
                        />
                      }
                      label="Online Ordering"
                    />
                    <Tooltip
                      placement="right"
                      title="In-store or online ordering, at least one should be selected."
                    >
                      <img alt="edit" src="/icons/icon_not.svg" />
                    </Tooltip>
                  </FormGroup>
                  <Box mt={2}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checked.member}
                            onChange={(e) => handleCheckBoxChange(e, "member")}
                          />
                        }
                        label="Member"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.checked.guest}
                            onChange={(e) => handleCheckBoxChange(e, "guest")}
                          />
                        }
                        label="Guest"
                      />
                      <Tooltip
                        placement="right"
                        title="Member or Guest, at least one should be selected."
                      >
                        <img alt="edit" src="/icons/icon_not.svg" />
                      </Tooltip>
                    </FormGroup>
                  </Box>
                  <Stack
                    sx={{
                      flexDirection: guest ? "row" : "column",
                      gap: "10px"
                    }}
                  >
                    <Stack direction="row" gap="20px" mt={2} width="100%">
                      {!state.checked.guest ? (
                        <InputControl
                          type="outlinedInput"
                          inputType="number"
                          inputLabel="Use Per Customer"
                          label="Use Per Customer"
                          placeholder="Use Per Customer"
                          name="use_per_customer"
                          onChange={(e) => onChange(e)}
                          value={state.data.use_per_customer}
                          endAdornment={
                            <InputAdornment position="start">
                              <Tooltip title="Zero or empty means unlimited">
                                <img alt="details" src="/icons/ic_info.svg" />
                              </Tooltip>
                            </InputAdornment>
                          }
                        />
                      ) : ""}
                      <InputControl
                        inputType="number"
                        name="use_per_day"
                        onChange={(e) => onChange(e)}
                        value={state.data.use_per_day}
                        type="outlinedInput"
                        inputLabel={state.checked.guest ? "Use per day per coupon" : "Use Per Day Per Customers"}
                        label={state.checked.guest ? "Use per day per coupon" : "Use Per Day Per Customers"}
                        placeholder={state.checked.guest ? "Use per day per coupon" : "Use Per Day Per Customers"}
                        endAdornment={
                          <InputAdornment position="start">
                            <Tooltip title={`Zero or empty means unlimited${guest ? ",Use per customer value should be set before setting this value." : "."}`}>
                              <img alt="details" src="/icons/ic_info.svg" />
                            </Tooltip>
                          </InputAdornment>
                        }
                      />
                    </Stack>
                    <Stack gap="20px" mt={2} width={"100%"}>
                      <InputControl
                        type="outlinedInput"
                        inputType="number"
                        name="time_lock_period"
                        label={state.checked.guest ? "time lock period per coupon" : "Time Lock Period Per Customer (Hours)"}
                        onChange={(e) => onChange(e)}
                        value={state.data.time_lock_period}
                        inputLabel={state.checked.guest ? "time lock period per coupon" : "Time Lock Period Per Customer (Hours)"}
                        placeholder={state.checked.guest ? "time lock period per coupon" : "Time Lock Period Per Customer (Hours)"}
                        error={validator.current.message(
                          "Time Lock Period Per Customer",
                          state.data.time_lock_period,
                          "integer|max:24,num"
                        )}
                        helperText={validator.current.message(
                          "Time Lock Period Per Customer",
                          state.data.time_lock_period,
                          "integer|max:24,num"
                        )}
                        endAdornment={
                          <InputAdornment position="start">
                            <Tooltip title={`Zero or empty means no time limit.${guest ? "Use per day per coupon" : "Use Per Day Per Customer"} value should be greater than 1 to set this value.`}>
                              <img alt="details" src="/icons/ic_info.svg" />
                            </Tooltip>
                          </InputAdornment>
                        }
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Box marginTop={3}>
                <Divider />
              </Box>

              <Grid container spacing={1} marginTop={3}>
                <Grid item sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Coupon Type
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack
                    sx={{
                      gap: "26px"
                    }}
                  >
                    <Stack>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="coupon_type"
                        value={state.data.coupon_type}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Auto generated coupon code"
                          value={1}
                          onChange={(e) => onRadioButtonChange(e)}
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label="Custom coupon code"
                          onChange={(e) => onRadioButtonChange(e)}
                        />
                      </RadioGroup>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: state.data.coupon_type == 2 ? "row" : "row-reverse",
                        gap: "10px"
                      }}
                    >
                      {state.data.coupon_type == 2 ? (
                        <InputControl
                          name="coupon_code"
                          label="Coupon Code"
                          onChange={(e) => onChange(e)}
                          disabled={
                            Number(state.data.coupon_type) !== 2
                          }
                          sx={{ width: "100%" }}
                          value={state.data.coupon_code}
                          error={
                            state.data.coupon_type === "2" &&
                            validator.current.message(
                              "Coupon Code",
                              state.data.coupon_code,
                              "required"
                            )
                          }
                          helperText={
                            state.data.coupon_type === "2" &&
                            validator.current.message(
                              "Coupon Code",
                              state.data.coupon_code,
                              "required"
                            )
                          }
                          required={state.data.coupon_type == 1}
                        />
                      ) : <Box width={"100%"} />}

                      <InputControl
                        type="outlinedInput"
                        inputType="number"
                        name="max_redeem_count"
                        onChange={(e) => onChange(e)}
                        value={state.data.max_redeem_count}
                        inputLabel={`Maximum Redeem Count${guest && state.data.coupon_type == 1 ? "*" : ""}`}
                        label={`Maximum Redeem Count${guest && state.data.coupon_type == 1 ? "*" : ""}`}
                        placeholder={`Maximum Redeem Count${guest && state.data.coupon_type == 1 ? "*" : ""}`}
                        sx={{ width: "100%" }}
                        endAdornment={
                          <InputAdornment position="start">
                            <Tooltip title="Maximum times the coupon can be used by all users. Zero or empty means unlimited">
                              <img alt="details" src="/icons/ic_info.svg" />
                            </Tooltip>
                          </InputAdornment>
                        }
                        error={guest && state.data.coupon_type == 1 ? validator.current.message(
                          "Maximum Redeem Count",
                          state.data.max_redeem_count,
                          "integer|num"
                        ) : ""}
                        helperText={guest && state.data.coupon_type == 1 ? validator.current.message(
                          "Maximum Redeem Count",
                          state.data.max_redeem_count,
                          "integer|num"
                        ) : ""}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Box marginTop={3}>
                <Divider />
              </Box>
              <Grid container spacing={2} marginTop={3}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Action Type
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="discount_type"
                    value={state.data.discount_type}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Percentage"
                      value={2}
                      onChange={(e) => onRadioButtonChange(e)}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Fixed"
                      onChange={(e) => onRadioButtonChange(e)}
                    />
                  </RadioGroup>

                  <Stack width={"100%"} direction="row" gap="20px" mt={2}>
                    <InputControl
                      type="number"
                      name="discount_amount"
                      label={
                        state.data.discount_type === 2
                          ? "Discount Amount (%)"
                          : "Discount Amount ($)"
                      }
                      onChange={(e) => onChange(e)}
                      value={state.data.discount_amount}
                      error={
                        state.data.discount_type === 2
                          ? validator.current.message(
                            "Discount Amount (%)",
                            state.data.discount_amount,
                            "required|numeric|max:100,num"
                          )
                          : validator.current.message(
                            "Discount Amount ($)",
                            state.data.discount_amount,
                            "required|numeric"
                          )
                      }
                      helperText={
                        state.data.discount_type === 2
                          ? validator.current.message(
                            "Discount Amount (%)",
                            state.data.discount_amount,
                            "required|numeric|max:100,num"
                          )
                          : validator.current.message(
                            "Discount Amount ($)",
                            state.data.discount_amount,
                            "required|numeric"
                          )
                      }
                      required
                    />
                    <InputControl
                      type="number"
                      name="max_discount_amount"
                      label="Maximum Discount Amount ($) "
                      disabled={state.data.discount_type == "1" ? true : false}
                      onChange={(e) => onChange(e)}
                      value={state.data.max_discount_amount}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Box marginTop={3}>
                <Divider />
              </Box>
              <Grid container spacing={2} marginTop={3}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Rules
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack
                    direction="row"
                    gap="24px"
                    width="100%"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      color="#636363"
                      fontWeight={400}
                      fontSize="14px"
                    >
                      Condition Type
                    </Typography>

                    <ToggleButtonGroup
                      color="primary"
                      value={state.data.condition_type}
                      exclusive
                      onChange={handleConditionType}
                      aria-label="Platform"
                      className="custom-pos"
                    >
                      {conditionType.map((item) => (
                        <ToggleButtonStyled
                          sx={{
                            width: "102px",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#636363",
                          }}
                          value={item.value}
                        >
                          {item.label}
                        </ToggleButtonStyled>
                      ))}
                    </ToggleButtonGroup>
                  </Stack>

                  {state?.data?.conditions?.map((value, index, rules) => {
                    const conditionsInOtherIndices = [];
                    rules.forEach((rule, i) => {
                      if (index !== i) {
                        conditionsInOtherIndices.push(rule);
                      }
                    });
                    const filteredOptions =
                      options.length > 0 &&
                      options.filter((op) => {
                        return !conditionsInOtherIndices.find(
                          (item) => item.id == op.value
                        );
                      });
                    return (
                      <>
                        {index > 0 && (
                          <Chip
                            label={
                              conditionType.find(
                                (item) =>
                                  item.value === state?.data?.condition_type
                              )?.label
                            }
                            color="primary"
                            variant="outlined"
                            size="small"
                            sx={{ bgcolor: "#1560D414", marginTop: "10px" }}
                            disabled={false}
                          />
                        )}
                        <Stack marginTop={3} direction="row" gap={1}>
                          <InputControl
                            type="dropdown"
                            name="conditions"
                            disableClearable
                            options={filteredOptions}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => {
                              handleOnChange(newValue, index, "event");
                            }}
                            value={
                              (filteredOptions?.length > 0 &&
                                value.id &&
                                filteredOptions?.find(
                                  (c) => c.value === value.id
                                )) ||
                              null
                            }
                            sx={{
                              width: "100%",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                sx={{
                                  borderRadius: "8px",
                                  ".MuiInputBase-root": { height: "44px" },
                                }}
                                label="Rule"
                              // required
                              // error={validator.current.message(
                              //   "Rule",
                              //   value.id,
                              //   "required"
                              // )}
                              // helperText={validator.current.message(
                              //   "Rule",
                              //   value.id,
                              //   "required"
                              // )}
                              />
                            )}
                          />

                          <Box marginTop={2}>
                            <div className="action-buttons">
                              <img
                                alt="delete"
                                onClick={() => {
                                  handleDelete(value.id, index);
                                }}
                                src="/icons/ic_delete.svg"
                              />
                            </div>
                          </Box>
                        </Stack>
                      </>
                    );
                  })}
                  <Box marginTop={3}>
                    <Stack>
                      <Stack direction="row" marginTop={2} width="100%" gap={2}>
                        <Button
                          variant="contained"
                          onClick={() => onAddConditions()}
                          disabled={disabledValue}
                        >
                          Add Rule
                        </Button>
                        <Button type="button" variant="text">
                          <Link
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            to={`/coupon-rules/create`}
                            onClick={(e) => {
                              e.stopPropagation();
                              onBlurTab();
                            }}
                          >
                            <Typography color="#1560D4" fontWeight={500}>
                              Create New Rule
                            </Typography>
                          </Link>
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                  {state.isInProductRule ? (
                    <>
                      <Divider sx={{ my: "12px" }} />
                      <Stack
                        sx={{
                          pt: "12px",
                        }}
                      >
                        <InputControl
                          type="number"
                          name="applicable_max_product_qty"
                          label="Maximum Product Qty"
                          onChange={(e) => onChange(e)}
                          value={state.data.applicable_max_product_qty}
                          error={validator.current.message(
                            "Maximum Product Qty",
                            state.data.applicable_max_product_qty,
                            "customMaxQty"
                          )}
                          helperText={validator.current.message(
                            "Maximum Product Qty",
                            state.data.applicable_max_product_qty,
                            "customMaxQty"
                          )}
                        />
                        <Stack
                          sx={{
                            flexDirection: "row",
                            gap: "4px",
                            alignItems: "center",
                            padding: "6px 4px",
                          }}
                        >
                          <img
                            style={{
                              height: "18px",
                            }}
                            alt="details"
                            src="/icons/ic_info.svg"
                          />
                          <Typography
                            variant="caption"
                            lineHeight="15px"
                            color="#666666"
                          >
                            1 or empty means 1, while 0 means unlimited. Only applicable for coupons with products rules.
                          </Typography>
                        </Stack>
                      </Stack>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      </ContentLayout>
    </>
  );
};
